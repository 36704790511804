<template>
  <div id="doctor-list" class="position-relative">
    <v-card class="filter-section">
      <v-row class="">
        <v-col cols="6">
          <v-card-title class="font-weight-bold "> {{$t('common.list.filters')}} </v-card-title>
        </v-col>
        <v-col cols="6" class="d-flex justify-end">
          <v-btn icon @click="showFilter = !showFilter">
            <v-icon>{{ showFilter ? icons.mdiMenuUp : icons.mdiMenuDown }}</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-expand-transition>
        <v-row class="px-2 ma-0" v-show="showFilter">
          <!-- group filter -->
          <v-col cols="12" sm="3">
            <v-select v-model="filters.group_id" :items="groups" item-text="title" item-value="value" dense outlined hide-details
              clearable placeholder="Search by group"></v-select>
          </v-col>

          <!-- name filter -->
          <v-col cols="12" sm="3">
            <v-text-field v-model="filters.name" single-line dense outlined hide-details clearable
              :placeholder="$t('common.list.searchByName')"></v-text-field>
          </v-col>
        </v-row>
      </v-expand-transition>
    </v-card>

    <v-card class="mt-5">
      <v-card-text class="d-flex flex-wrap gap-4">
        <v-btn color="primary" class="" :loading="loading" :disabled="loading"
          @click="loadDoctors">
          <v-icon class="prefix-icon">{{ icons.mdiRefresh }}</v-icon>
          <span>
            {{$t('common.list.refresh')}}
          </span>
        </v-btn>

        <v-spacer />

        <import-dialog />
      </v-card-text>

      <!-- table -->
      <v-data-table
        :headers="tableColumns"
        :items="tableItems"
        :options.sync="options"
        :server-items-length="tableTotal"
        :loading="loading"
        class="text-no-wrap"
        :headerProps="headerprops"
      >
              <!-- ID -->
        <template #[`item.id`]="{ item }">
          #{{ item.id }}
        </template>
        <!-- name -->
        <template #[`item.name`]="{ item }">
          <span class="text-no-wrap">{{ item.name.en }}</span>
        </template>

        <!-- address-->
        <template #[`item.address`]="{ item }">
          <span class="text-no-wrap">{{ item.address.en }}</span>
        </template>

        <!-- active-->
        <template #[`item.active`]="{ item }">
          <span class="text-no-wrap">{{ item.active ? 'Yes' : 'No' }}</span>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mdiDeleteOutline, mdiDotsVertical, mdiMenuDown, mdiMenuUp, mdiPencilOutline, mdiPlus, mdiRefresh } from '@mdi/js'

import ImportDialog from '@/modules/doctor/components/ImportDialog'
import { t } from '@/plugins/i18n'
import store from '@/store'
import { formatDate } from '@core/utils/filter'
import { computed, onMounted, onUnmounted, ref } from '@vue/composition-api'
import useDoctorList from './useDoctorList'

export default {
  components: { ImportDialog },
  setup() {
    const { registerStore, unregisterStore, tableItems, tableColumns, tableTotal, options, filters, loading, loadDoctors } = useDoctorList()

    registerStore()

    const groups = computed(() => {
      return store.getters['doctor_list/getGroups'].map(item => ({
        value: item.id,
        title: item.name,
      }))
    })

    const headerprops = ref({
      'sort-icon': mdiMenuDown,
    })
    const showFilter = ref(true)

    onMounted(async () => {
      await store.dispatch('doctor_list/getGroups')
    })

    onUnmounted(() => {
      unregisterStore()
    })

    return {
      loading,
      t,
      formatDate,
      icons: {
        mdiDotsVertical,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiMenuDown,
        mdiMenuUp,
        mdiRefresh,
        mdiPlus,
      },

      tableColumns,
      tableItems,
      tableTotal,
      options,

      groups,
      filters,

      loadDoctors,
      headerprops,
      showFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
#doctor-list {
  .search {
    max-width: 300px;
  }
}
</style>
