var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "position-relative",
    attrs: {
      "id": "doctor-list"
    }
  }, [_c('v-card', {
    staticClass: "filter-section"
  }, [_c('v-row', {}, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-card-title', {
    staticClass: "font-weight-bold "
  }, [_vm._v(" " + _vm._s(_vm.$t('common.list.filters')) + " ")])], 1), _c('v-col', {
    staticClass: "d-flex justify-end",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        _vm.showFilter = !_vm.showFilter;
      }
    }
  }, [_c('v-icon', [_vm._v(_vm._s(_vm.showFilter ? _vm.icons.mdiMenuUp : _vm.icons.mdiMenuDown))])], 1)], 1)], 1), _c('v-expand-transition', [_c('v-row', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showFilter,
      expression: "showFilter"
    }],
    staticClass: "px-2 ma-0"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "3"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.groups,
      "item-text": "title",
      "item-value": "value",
      "dense": "",
      "outlined": "",
      "hide-details": "",
      "clearable": "",
      "placeholder": "Search by group"
    },
    model: {
      value: _vm.filters.group_id,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "group_id", $$v);
      },
      expression: "filters.group_id"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "single-line": "",
      "dense": "",
      "outlined": "",
      "hide-details": "",
      "clearable": "",
      "placeholder": _vm.$t('common.list.searchByName')
    },
    model: {
      value: _vm.filters.name,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "name", $$v);
      },
      expression: "filters.name"
    }
  })], 1)], 1)], 1)], 1), _c('v-card', {
    staticClass: "mt-5"
  }, [_c('v-card-text', {
    staticClass: "d-flex flex-wrap gap-4"
  }, [_c('v-btn', {
    attrs: {
      "color": "primary",
      "loading": _vm.loading,
      "disabled": _vm.loading
    },
    on: {
      "click": _vm.loadDoctors
    }
  }, [_c('v-icon', {
    staticClass: "prefix-icon"
  }, [_vm._v(_vm._s(_vm.icons.mdiRefresh))]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('common.list.refresh')) + " ")])], 1), _c('v-spacer'), _c('import-dialog')], 1), _c('v-data-table', {
    staticClass: "text-no-wrap",
    attrs: {
      "headers": _vm.tableColumns,
      "items": _vm.tableItems,
      "options": _vm.options,
      "server-items-length": _vm.tableTotal,
      "loading": _vm.loading,
      "headerProps": _vm.headerprops
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.options = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "item.id",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" #" + _vm._s(item.id) + " ")];
      }
    }, {
      key: "item.name",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('span', {
          staticClass: "text-no-wrap"
        }, [_vm._v(_vm._s(item.name.en))])];
      }
    }, {
      key: "item.address",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('span', {
          staticClass: "text-no-wrap"
        }, [_vm._v(_vm._s(item.address.en))])];
      }
    }, {
      key: "item.active",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('span', {
          staticClass: "text-no-wrap"
        }, [_vm._v(_vm._s(item.active ? 'Yes' : 'No'))])];
      }
    }], null, true)
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }